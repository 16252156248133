import { isBefore } from "date-fns";
import { FC, ReactElement, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { useQueryClient } from "@tanstack/react-query";

import { useMeetings } from "../../hooks/meetings/useMeetings";
import { BaseForm, FormRole } from "../../models/Form/BaseForm";
import { NotificationType } from "../../models/Notification";
import { showNotification } from "../../redux/currentApp/slice";
import { selectCurrentDocumentCompetences } from "../../redux/currentDocument/competences/selectors";
import { selectCurrentDocumentObjectives } from "../../redux/currentDocument/objectives/selectors";
import {
  isLoadingValidateDocumentEvaluation,
  selectValidateDocumentEvaluationError,
  selectValidateDocumentEvaluationStatus
} from "../../redux/currentDocument/selectors";
import {
  clearValidateDocumentEvaluationStatus,
  validateDocumentEvaluation
} from "../../redux/currentDocument/slice";
import { formatISODateStr, parseISODateStr } from "../../utils/dates";
import { classNames } from "../../utils/styles";
import ErrorBanner from "../banners/ErrorBanner";
import InfoBanner from "../banners/InfoBanner";
import WarningBanner from "../banners/WarningBanner";
import Spinner from "../loaders/Spinner";
import Modal from "../modal/Modal";
import SafeHTMLText from "../texts/SafeHTMLText";

const mapStateToProps = createStructuredSelector({
  objectives: selectCurrentDocumentObjectives,
  competences: selectCurrentDocumentCompetences,
  isLoading: isLoadingValidateDocumentEvaluation,
  status: selectValidateDocumentEvaluationStatus,
  formError: selectValidateDocumentEvaluationError
});

const mapDispatchToProps = {
  validateDocument: validateDocumentEvaluation,
  clearStatus: clearValidateDocumentEvaluationStatus,
  notify: showNotification
};

interface OwnProps {
  document: BaseForm;
  role: FormRole;
  closeModal: () => void;
}

type Props = OwnProps & ReduxProps;

const DocumentValidationModal: FC<Props> = (props): ReactElement => {
  const queryClient = useQueryClient();
  const { t } = useTranslation("translation", {
    keyPrefix: "form.evaluation"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const {
    document,
    objectives,
    competences,
    role,
    isLoading,
    status,
    formError,
    validateDocument,
    clearStatus,
    notify,
    closeModal
  } = props;
  const isManager = role === FormRole.manager;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Get meetings for the document
  const { data: meetings = [] } = useMeetings(document.formUUID);

  useEffect(() => {
    if (isLoading) return;

    if (formError) {
      setErrorMessage(formError);
    }

    if (status === "success") {
      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.validationSucess"),
        message: t("validation.messages.success")
      });

      // Cleanup
      clearStatus();
      queryClient.invalidateQueries({
        queryKey: ["activities", document.formUUID]
      });
      closeModal();
    }
  }, [
    isLoading,
    status,
    formError,
    clearStatus,
    queryClient,
    document,
    notify,
    closeModal,
    t,
    tCommon
  ]);

  const hasScheduledMeetings = useMemo(() => {
    // Parse each meeting and check if there is at least one scheduled
    // return meetings.some((meeting) => {
    //   const meetingDate = parseISODateStr(meeting.meetingDate);

    //   return meetingDate != null && isBefore(new Date(), meetingDate);
    // });
    return meetings.some((meeting) => meeting.meetingDate != null);
  }, [meetings]);

  const nextMeetingDate: string | undefined = useMemo(() => {
    // Find the next meeting that is scheduled (take the latest)
    const nextMeeting = meetings
      .filter((meeting) => {
        const meetingDate = parseISODateStr(meeting.meetingDate);

        return meetingDate != null && isBefore(new Date(), meetingDate);
      })
      ?.sort((a, b) => {
        const aDate = parseISODateStr(a.meetingDate);
        const bDate = parseISODateStr(b.meetingDate);
        return aDate.getTime() - bDate.getTime();
      })
      ?.slice(-1)[0];

    return nextMeeting?.meetingDate != null
      ? formatISODateStr(nextMeeting?.meetingDate, "MMMM do, yyyy h:mm a")
      : undefined;
  }, [meetings]);

  const objectiveWeightsTotal = useMemo(() => {
    return (
      objectives?.reduce((acc, objective) => acc + objective.weight, 0) ?? 0
    );
  }, [objectives]);

  const remainingObjectivesToScore = useMemo(() => {
    return (
      objectives?.filter(
        (objective) => objective.evaluation?.[role]?.score == null
      ).length ?? 0
    );
  }, [objectives, role]);

  const remainingCompetencesToScore = useMemo(() => {
    return (
      competences?.filter(
        (competence) => competence.evaluation?.[role]?.score == null
      ).length ?? 0
    );
  }, [competences, role]);

  const remainingGlobalCompetenceScore = useMemo(() => {
    if (
      document.relatedFunction.scoreCompetences &&
      document.relatedFunction.scoreObjectives
    ) {
      return document?.evaluation?.globalCompetence?.score == null;
    }

    return false;
  }, [document]);

  // Shared validation
  const canValidate = useMemo(() => {
    return (
      (isManager ? objectiveWeightsTotal === 100 : true) &&
      remainingObjectivesToScore === 0 &&
      remainingCompetencesToScore === 0 &&
      !remainingGlobalCompetenceScore
    );
  }, [
    isManager,
    objectiveWeightsTotal,
    remainingObjectivesToScore,
    remainingCompetencesToScore,
    remainingGlobalCompetenceScore
  ]);

  // Manager validation
  const isValid = useMemo(() => {
    return isManager ? canValidate && hasScheduledMeetings : canValidate;
  }, [isManager, canValidate, hasScheduledMeetings]);

  // Check if the next meeting is in the past
  // const isNextMeetingInThePast = useMemo(() => {
  //   return (
  //     nextMeetingDate != null &&
  //     isBefore(new Date(), parseISODateStr(nextMeetingDate))
  //   );
  // }, [nextMeetingDate]);

  return (
    <Modal closeModal={closeModal}>
      <>
        <div className="sm:flex sm:items-start">
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                {t(`validation.title.${role}`)}
              </h2>
              {isValid ? (
                <div>
                  <SafeHTMLText
                    html={t(`validation.congratulations.validation.${role}`, {
                      year: document.relatedCycle.year
                    })}
                    className="mt-1 mb-2 text-sm leading-6 text-gray-900"
                  />
                  {nextMeetingDate && (
                    <div className="mt-5">
                      <InfoBanner
                        message={t("validation.congratulations.nextMeeting", {
                          date: nextMeetingDate
                        })}
                        isHtml
                      />
                    </div>
                  )}
                  {isManager && (
                    <div className="mt-5">
                      <WarningBanner message={t("validation.warning")} isHtml />
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div>
                    {isManager && (
                      <h3 className="my-3 font-semibold leading-6 text-red-600">
                        {t("validation.error.title")}
                      </h3>
                    )}
                    <SafeHTMLText
                      html={t(`validation.error.subtitle.${role}`)}
                      className="mt-1 mb-2 text-sm leading-6 text-gray-600"
                    />
                    {objectiveWeightsTotal !== 100 && (
                      <div className="mt-5">
                        <ErrorBanner
                          message={t(
                            `validation.error.objectiveWeightsTotal.${role}`,
                            {
                              total: objectiveWeightsTotal
                            }
                          )}
                          isHtml
                        />
                      </div>
                    )}
                    {!hasScheduledMeetings && (
                      <div className="mt-5">
                        <ErrorBanner
                          message={t(
                            `validation.error.hasScheduledMeetings.${role}`
                          )}
                        />
                      </div>
                    )}
                    {remainingObjectivesToScore > 0 && (
                      <div className="mt-5">
                        <ErrorBanner
                          message={t(
                            "validation.error.remainingObjectivesToScore",
                            {
                              count: remainingObjectivesToScore
                            }
                          )}
                          isHtml
                        />
                      </div>
                    )}
                    {remainingCompetencesToScore > 0 && (
                      <div className="mt-5">
                        <ErrorBanner
                          message={t(
                            "validation.error.remainingCompetencesToScore",
                            {
                              count: remainingCompetencesToScore
                            }
                          )}
                          isHtml
                        />
                      </div>
                    )}
                    {isManager && remainingGlobalCompetenceScore && (
                      <div className="mt-5">
                        <ErrorBanner
                          message={t(
                            "validation.error.remainingGlobalCompetenceScore"
                          )}
                        />
                      </div>
                    )}
                    {isManager && (
                      <div className="mt-5">
                        <WarningBanner
                          message={t("validation.warning")}
                          isHtml
                        />
                      </div>
                    )}
                  </div>
                  {errorMessage != null && (
                    <div className="mt-5">
                      <ErrorBanner message={errorMessage} />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          {isManager && (
            <button
              type="button"
              className={classNames(
                "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto",
                isLoading || !isValid
                  ? "cursor-not-allowed opacity-50 ml-2"
                  : ""
              )}
              disabled={isLoading || !isValid}
              onClick={() => {
                if (document) {
                  validateDocument({ formUUID: document.formUUID, role });
                }
              }}
            >
              {isLoading && <Spinner size="small" />}
              {t("validation.confirmButton")}
            </button>
          )}
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={() => closeModal()}
          >
            {tCommon("actions.cancel")}
          </button>
        </div>
      </>
    </Modal>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(DocumentValidationModal);
