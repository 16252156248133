import Axios, { CancelTokenSource } from 'axios';

import { call, put, select, takeLatest } from '@redux-saga/core/effects';

import { Employee } from '../../models/Employee/Employee';
import { BaseForm, Phase } from '../../models/Form/BaseForm';
import { Cycle } from '../../models/Form/Cycle';
import { cancelConfirmedForm } from '../../services/FormService';
import { getTeamByCycle, getTeamlistDocuments } from '../../services/TeamlistService';
import { selectCurrentCycle } from '../currentApp/selectors';
import { setCurrentCycle } from '../currentApp/slice';
import { selectSelectedTeamManager } from './selectors';
import {
  cancelForm, cancelFormError, cancelFormSuccess, loadCurrentTeamlist, loadCurrentTeamlistError,
  loadCurrentTeamlistSuccess, loadTeamManagers, loadTeamManagersError, loadTeamManagersSuccess
} from './slice';

function* getTeamlistSaga(action: ReturnType<typeof loadCurrentTeamlist>) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { employeeId, cycleId, phase } = action.payload;

  try {
    const data: BaseForm[] = yield call(
      // getTeamlist,
      getTeamlistDocuments,
      signal.token,
      employeeId,
      cycleId,
      phase
    );

    yield put(loadCurrentTeamlistSuccess({ data }));
  } catch (error: any) {
    yield put(loadCurrentTeamlistError(error));
  }
}

function* fetchTeamManagersSaga(action: ReturnType<typeof setCurrentCycle>) {
  const { cycle } = action.payload;

  yield put(loadTeamManagers({ cycleId: cycle._id }));
}

function* getTeamManagersSaga(action: ReturnType<typeof loadTeamManagers>) {
  const signal: CancelTokenSource = Axios.CancelToken.source();
  const { cycleId } = action.payload;

  try {
    const data: Employee[] = yield call(getTeamByCycle, signal.token, cycleId);

    yield put(loadTeamManagersSuccess({ data }));
  } catch (error: any) {
    yield put(loadTeamManagersError(error));
  }
}

function* cancelFormSaga(action: ReturnType<typeof cancelForm>) {
  const { formUUID } = action.payload;

  try {
    yield call(cancelConfirmedForm, formUUID);

    yield put(cancelFormSuccess({ formUUID }));

    // Trigger a refresh of the teamlist
    const currentCycle: Cycle = yield select(selectCurrentCycle);
    const employee: Employee = yield select(selectSelectedTeamManager);

    yield put(loadCurrentTeamlist({ employeeId: employee._id, cycleId: currentCycle._id, phase: Phase.review }));
  } catch (error: any) {
    yield put(cancelFormError(error));
  }
}

export default function* currentTeamlistSaga() {
  yield takeLatest(loadCurrentTeamlist.type, getTeamlistSaga);
  yield takeLatest(loadTeamManagers.type, getTeamManagersSaga);
  yield takeLatest(cancelForm.type, cancelFormSaga);

  // Listen for changes to the current cycle
  yield takeLatest(setCurrentCycle.type, fetchTeamManagersSaga);
}
