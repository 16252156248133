import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";

import ActionButton from "../../components/buttons/ActionButton";
import Spinner from "../../components/loaders/Spinner";
import SubNavBar from "../../components/navigation/SubNavBar";
import CycleSelector from "../../components/selectors/CycleSelector";
import EmployeeSelector from "../../components/selectors/EmployeeSelector";
import { Employee } from "../../models/Employee/Employee";
import { Phase } from "../../models/Form/BaseForm";
import { selectCurrentCycle } from "../../redux/currentApp/selectors";
import { selectCurrentEmployee } from "../../redux/currentEmployee/selectors";
import {
  isLoadingCurrentTeamlist,
  isLoadingTeamManagers,
  selectCurrentTeamlist,
  selectSelectedTeamManager,
  selectTeamManagers
} from "../../redux/currentTeamlist/selectors";
import {
  loadCurrentTeamlist,
  loadTeamManagers,
  setSelectedTeamManager
} from "../../redux/currentTeamlist/slice";
import { downloadFormMetrics } from "../../services/DownloadsService";
import TargetTeamlist from "./TargetTeamlist";
import ReviewTeamlist from "./ReviewTeamlist";
import PhaseSelector from "../../components/selectors/PhaseSelector";

const mapStateToProps = createStructuredSelector({
  employee: selectCurrentEmployee,
  currentCycle: selectCurrentCycle,
  teamlist: selectCurrentTeamlist,
  teamManagers: selectTeamManagers,
  currentManager: selectSelectedTeamManager,
  isLoadingTeamlist: isLoadingCurrentTeamlist,
  isLoadingManagers: isLoadingTeamManagers
});

const mapDispatchToProps = {
  loadCurrentTeamlist,
  loadTeamManagers,
  setSelectedTeamManager
};

type OwnProps = ReduxProps;

const TeamList: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const {
    employee,
    currentCycle,
    teamlist,
    teamManagers,
    currentManager,
    loadCurrentTeamlist,
    loadTeamManagers,
    setSelectedTeamManager,
    isLoadingTeamlist,
    isLoadingManagers
  } = props;
  const [selectedPhase, setSelectedPhase] = useState(
    currentCycle?.prOpened ? Phase.review : Phase.target
  );
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (currentCycle?._id != null && teamManagers == null) {
      loadTeamManagers({
        cycleId: currentCycle._id
      });
    }
  }, [teamManagers, currentCycle?._id, loadTeamManagers]);

  useEffect(() => {
    if (currentManager == null && employee?.isManager && teamManagers != null) {
      setSelectedTeamManager({ manager: employee });
    }
  }, [currentManager, teamManagers, employee, setSelectedTeamManager]);

  useEffect(() => {
    if (currentCycle?._id != null && currentManager?._id != null) {
      loadCurrentTeamlist({
        employeeId: currentManager._id,
        cycleId: currentCycle._id,
        phase: currentCycle?.prOpened ? Phase.review : Phase.target
      });

      setSelectedPhase(currentCycle?.prOpened ? Phase.review : Phase.target);
    }
  }, [
    currentCycle?._id,
    currentManager?._id,
    loadCurrentTeamlist,
    currentCycle?.prOpened
  ]);

  const downloadFile = (file: Blob, filename: string) => {
    const url = URL.createObjectURL(file);

    // Create a temporary anchor element
    const tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.download = filename;

    // Append to the body
    document.body.appendChild(tempLink);
    tempLink.click(); // Trigger the download

    // Clean up
    document.body.removeChild(tempLink);
    URL.revokeObjectURL(url);
  };

  const handleDownloadFormMetrics = async () => {
    if (!currentCycle?._id || !currentManager?._id) return;

    setIsDownloading(true);
    const phase = currentCycle?.prOpened ? Phase.review : Phase.target;
    const data = await downloadFormMetrics(
      currentCycle?._id,
      phase,
      currentManager?._id
    );

    if (data) {
      const manager = currentManager?.name.first.toLowerCase();
      const filename = `form_data_${manager}_${phase}_${currentCycle?.year}.xlsx`;
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      downloadFile(blob, filename);
    }
    setIsDownloading(false);
  };

  return (
    <>
      {isLoadingTeamlist || isLoadingManagers ? (
        <div className="text-center m-10">
          <Spinner />
        </div>
      ) : (
        <>
          <SubNavBar
            breadcrumbs={[
              t("navigation.teamlist"),
              t("common.breadcrumps.overview")
            ]}
            lang={employee?.lang ?? "fr"}
          />
          <main className="flex flex-col mx-auto max-w-7xl">
            {/* Filters: cycle */}
            <div className="py-4 px-4 sm:px-6 lg:px-8">
              <div className="flex items-center space-x-5">
                <CycleSelector
                  lang={employee?.lang ?? "en"}
                  currentCycle={currentCycle}
                />
                <EmployeeSelector
                  employeeOptions={teamManagers || []}
                  selectedEmployee={currentManager}
                  defaultLabel={t("common.selectors.teams")}
                  onSelectEmployee={(selection: Employee) =>
                    setSelectedTeamManager({ manager: selection })
                  }
                />
                {currentCycle?.prOpened && (
                  <PhaseSelector
                    initialPhase={selectedPhase}
                    onSelectPhase={(phase: Phase) => {
                      setSelectedPhase(phase);
                    }}
                  />
                )}
                <div className="mt-2">
                  <ActionButton
                    label={t("common.actions.download")}
                    onClick={handleDownloadFormMetrics}
                    icon={ArrowDownTrayIcon}
                    disabled={isDownloading}
                  />
                </div>
              </div>
            </div>

            {/* Documents metrics */}
            {/* <div className="border-t border-white/10 pt-11">
              <h2 className="px-4 text-base font-semibold leading-7 sm:px-6 lg:px-8">
                {t("metrics.title")}
              </h2>
              <div className="px-4 sm:px-6 lg:px-8">
                <DocumentListMetrics metrics={metrics} />
              </div>
            </div> */}

            {employee && teamlist && currentCycle && (
              <>
                {selectedPhase === Phase.target ? (
                  <TargetTeamlist
                    documents={teamlist}
                    currentCycle={currentCycle}
                    employee={employee}
                  />
                ) : (
                  <ReviewTeamlist documents={teamlist} employee={employee} />
                )}
              </>
            )}
          </main>
        </>
      )}
    </>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(TeamList);
