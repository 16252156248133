import { connect, ErrorMessage, Field, getIn } from "formik";
import { FC, ReactElement } from "react";

import { classNames } from "../../utils/styles";

type OwnProps = {
  label: string;
  name: string;
  helperText?: string;
  disabled?: boolean;
};

const InputCheckbox: FC<OwnProps> = (props): ReactElement => {
  const { formik, label, name, disabled = false, helperText } = props;
  const error = getIn(formik.errors, name);
  const touch = getIn(formik.touched, name);

  return (
    <>
      <div className="relative flex gap-x-3">
        <div className="flex h-6 items-center">
          <Field name={name}>
            {({ field }) => (
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  {...field}
                  type="checkbox"
                  id={name}
                  checked={field.value}
                  className={classNames(
                    "h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600",
                    disabled ? "text-slate-600 cursor-not-allowed" : "",
                    touch && error
                      ? "border-red-300 text-red-900 focus:border-red-500"
                      : ""
                  )}
                  disabled={disabled}
                  aria-describedby={`${name}-error`}
                />
              </div>
            )}
          </Field>
        </div>
        <div className="text-sm leading-6">
          <label
            htmlFor={name}
            className="text-sm font-medium leading-6 text-gray-900"
          >
            {label}
          </label>
          {helperText && <p className="mt-1 text-xs text-gray-600">{helperText}</p>}
        </div>
      </div>
      <ErrorMessage
        name={name}
        component="p"
        className="mt-2 text-xs text-red-600"
        id={`${name}-error`}
      />
    </>
  );
};

export default connect<OwnProps, any>(InputCheckbox);
