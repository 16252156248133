import { FC, ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { RocketLaunchIcon } from "@heroicons/react/24/outline";

import InfoBanner from "../../../../components/banners/InfoBanner";
import WarningBanner from "../../../../components/banners/WarningBanner";
import SubSectionHeader from "../../../../components/headings/SubSectionHeader";
import EmptyObjectivesList from "../../../../components/objectives/EmptyObjectivesList";
import ObjectivesTable from "../../../../components/objectives/ObjectivesTable";
import { ActionMode } from "../../../../models/Actions";
import {
  Employee,
  PersonalGrading
} from "../../../../models/Employee/Employee";
import { Phase } from "../../../../models/Form/BaseForm";
import { openModal } from "../../../../redux/currentApp/slice";
import { selectCurrentDocumentObjectives } from "../../../../redux/currentDocument/objectives/selectors";
import {
  selectCurrentDocumentGrade,
  selectCurrentDocumentMode,
  selectCurrentDocumentPhase,
  selectShowManagerEvaluation
} from "../../../../redux/currentDocument/selectors";
import { objectiveLinks } from "../../../../utils/links";

const mapStateToProps = createStructuredSelector({
  objectives: selectCurrentDocumentObjectives,
  mode: selectCurrentDocumentMode,
  showManagerEvaluation: selectShowManagerEvaluation,
  currentPhase: selectCurrentDocumentPhase,
  currentGrade: selectCurrentDocumentGrade
});

const mapDispatchToProps = {
  openModal
};

interface OwnProps {
  documentId: string;
  employee: Employee;
  isManager: boolean;
}

const PerformanceObjectives: FC<OwnProps & ReduxProps> = (
  props
): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "objectives"
  });
  const {
    documentId,
    objectives = [],
    employee,
    isManager,
    mode,
    showManagerEvaluation,
    currentPhase,
    currentGrade,
    openModal
  } = props;

  const openAddNewObjectiveModal = () => {
    openModal({
      modal: {
        component: "AddObjectiveModal",
        props: {
          employeeId: employee?._id,
          documentId,
          isManager,
          currentGrade
        }
      }
    });
  };

  const objectiveWeightsTotal = objectives.reduce(
    (acc, objective) => acc + objective.weight,
    0
  );

  // Calculate the total score of all objectives.
  // For each objective, we calculate the score by multiplying the weight
  // by the evaluation scoreWeight (%). Then we sum all the scores, and divide
  // by the number of objectives to get the average score.
  // The average score is then multiplied by 100 to get the total score.
  const totalScore = useMemo(() => {
    if (currentPhase !== Phase.target) {
      return objectives.reduce((acc, objective) => {
        const weight = objective?.weight ?? 0;
        const scoreWeight = objective.evaluation?.manager?.scoreWeight ?? 0;
        return acc + (weight * scoreWeight) / 100;
      }, 0);
    }

    return undefined;
  }, [objectives, currentPhase]);

  return (
    <div className="my-10 mx-auto max-w-7xl">
      <SubSectionHeader
        title={t("title")}
        link={
          currentPhase === Phase.target
            ? objectiveLinks?.[employee?.lang ?? "fr"]
            : undefined
        }
        linkLabel={t("infoLinkLabel")}
        icon={
          <RocketLaunchIcon
            className="h-6 w-6 text-teal-500"
            aria-hidden="true"
          />
        }
        score={showManagerEvaluation ? totalScore : undefined}
        actionLabel={t("actions.add")}
        onActionClick={
          currentPhase === Phase.target && mode === ActionMode.EDIT
            ? () => {
                openAddNewObjectiveModal();
              }
            : undefined
        }
      />
      <div className="my-5">
        <InfoBanner
          title={t(`infoBanner.${currentPhase}.title`)}
          message={t(`infoBanner.${currentPhase}.message`)}
          isHtml
        />
      </div>
      {currentPhase === Phase.target &&
        currentGrade === PersonalGrading.master && (
          <div className="mt-3 mb-5">
            <WarningBanner
              message={t(
                `infoBanner.target.master.${isManager ? "manager" : "employee"}`
              )}
              isHtml
            />
          </div>
        )}
      {objectives.length > 0 && objectiveWeightsTotal !== 100 && (
        <div className="mt-3 mb-5">
          <WarningBanner
            message={t(
              `validation.totalWeight.${isManager ? "manager" : "employee"}`,
              {
                total: objectiveWeightsTotal
              }
            )}
            isHtml
          />
        </div>
      )}
      <ObjectivesTable
        documentId={documentId}
        objectives={objectives}
        lang={employee?.lang}
        currentPhase={currentPhase ?? Phase.target}
        showManagerEvaluation={showManagerEvaluation}
      />
      {objectives?.length === 0 && (
        <EmptyObjectivesList
          onClick={
            currentPhase === Phase.target && mode === ActionMode.EDIT
              ? () => {
                  openAddNewObjectiveModal();
                }
              : undefined
          }
        />
      )}
    </div>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(PerformanceObjectives);
